<template>
  <div class="flex justify-center items-center mt-10">
    <div class="bg-white p-2 rounded-lg w-6/12 shadow-sm">
      <div class="text-center my-4">
        <h2 class="text-lg font-bold">
          Ver respuesta de la solicitud
        </h2>
      </div>
      <div class="flex flex-wrap content-around">
        <div class="w-full flex flex-col items-center py-5 px-2 ">
          <div class="px-2 w-full flex flex-col">
            <div class="mb-4">
              <label class="font-medium text-sm">Observaciones</label>
              <Textarea
                rows="6" cols="30"
                class="w-full"
                inputClass="w-full"
                placeholder="Escribir..."
                v-model="model.respuesta"
              />
              <MessageError :text="errors.observacion"/>
            </div>

            <div class="px-2 w-full">
              <div style="max-height: 150px; overflow-y: auto;">
                <div class="flex justify-between" v-for="(item, key) in solicitud.paths" :key="key">
                  <div class="flex mt-2">
                    <button @click="verPdf(item.urlPath)">
                      <i class="pi pi-file-pdf mr-2 text-black cursor-pointer"></i>
                    </button>
                    <p>{{ item.fileName }}</p>
                  </div>
                  <div>
                    <button @click="quitarPdf(item)"><i class="pi pi-trash mr-2 text-red-600"></i></button>
                  </div>
                </div>
              </div>
            </div>

            <div class=" mt-4 w-full mb-4 flex flex-col">
              <button label="Show" icon="pi pi-external-link" @click="openMaximizable" class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
                Agregar nuevo soporte
              </button>
              <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
                <div>
                  <div>
                    <label class="text-sm" for="titulo">Titulo</label>
                    <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
                           class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  </div>
                  <div class="my-2">
                    <label class="text-sm">Adjunte el documento</label>
                    <div class="mt-2">
                      <button class="w-full">
                        <FilePond ref="pond"/>
                      </button>
                    </div>
                  </div>
                </div>
                <template class="text-center" #footer>
                  <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
                </template>
              </Dialog>
            </div>
          </div>
        </div>
        <div class="w-full flex justify-between px-2 mb-9">
          <Button label="Cancelar"
                  class="bg-blue-900 p-button-outlined"
                  style="width: 244px;"
                  @click="$router.go(-1)"
          />
          <Button label="Actualizar"
                  class="bg-blue-900"
                  style="width: 244px;"
                  type="button"
                  @click="handleNext"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import solicitudesStore from '../../../../store/solicitudes.store'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { useRouter, useRoute } from 'vue-router'
import FilePondStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/filepond.store'
import FilePond from '../../../uploadFilePoundNew/index.vue'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'

export default {
  name: 'responderSolicitud',
  components: {
    FilePond
  },
  setup () {
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const displayMaximizable = ref(false)
    const adjuntos = ref([])
    const pond = ref()
    const title = ref('')
    const solicitud = ref({
      paths: []
    })
    const _PdfService = new PdfService()

    /** state **/
    const schema = yup.object({
      respuesta: yup.string().nullable().required('El campo es requerido').label('')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('respuesta')

    /** data **/

    /** computed **/
    const tipoSolicitudes = computed(() => solicitudesStore.getters.solicitudTipo)
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )

    /** methodos **/
    const handleNext = handleSubmit((values) => {
      if (!values) return console.error('Error => validation')
      values.paths = adjuntos.value
      values.id = route.params.id
      solicitudesStore.dispatch('reply', values).then(({ data }) => {
        if (data.error) {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Error al crear solicitud', life: 3000 })
        } else {
          Swal.fire({
            title: 'Exito',
            text: 'Solicitud actualizada con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              router.go(-1)
            }
          })
        }
      })
    })

    const openMaximizable = () => {
      displayMaximizable.value = true
    }

    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        adjuntos.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        const values = {
          paths: adjuntos.value,
          id: route.params.id
        }
        solicitudesStore.dispatch('reply', values).then(({ data }) => {
          if (data.error) {
            toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Error al crear solicitud', life: 3000 })
          } else {
            Swal.fire({
              title: 'Exito',
              text: 'Documento cargado con exito',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            })
            solicitudesStore.dispatch('findSolicitud', route.params.id).then((data) => {
              solicitud.value = data
              model.respuesta = data.respuesta
            })
            adjuntos.value = []
          }
        })
        displayMaximizable.value = false
        title.value = ''
      })
    }

    const quitarPdf = ({ id }) => {
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de quitar el documento?',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          solicitudesStore.dispatch('removePdf', id).then(() => {
            solicitudesStore.dispatch('findSolicitud', route.params.id).then((data) => {
              solicitud.value = data
              model.respuesta = data.respuesta
            })
          })
        }
      })
    }

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    /** hooks **/
    onMounted(async () => {
      solicitudesStore.dispatch('findSolicitud', route.params.id).then((data) => {
        solicitud.value = data
        model.respuesta = data.respuesta
      })
    })

    return {
      errors,
      model,
      handleNext,
      tipoSolicitudes,
      displayMaximizable,
      pond,
      title,
      adjuntos,
      solicitud,
      openMaximizable,
      closeMaximizable,
      quitarPdf,
      verPdf
    }
  }
}
</script>

<style scoped>

</style>
